var datefield = document.createElement("input")
datefield.setAttribute("type", "date")
if (datefield.type != "date") { //if browser doesn't support input type="date", load files for jQuery UI Date Picker
    document.write('<link href="https://code.jquery.com/ui/1.12.1/themes/base/jquery-ui.css" rel="stylesheet" type="text/css" />\n')
    document.write('<script src="https://code.jquery.com/ui/1.12.1/jquery-ui.min.js"><\/script>\n');
    document.write('<script src="js/datepicker-nl-BE-min.js"><\/script>\n');
}

$(document).ready(function() {
    $('#home').addClass('active');
    $("#nav1").on('click', function() {
        if ($('#home').css('display') != 'block') {
            hideAll('#home');
            $('#home').addClass('active');
            $('.nav hr').css('margin-left', '0%');
            ga('set', 'page', '/home');
            ga('send', 'pageview');
        }
    });
    $("#nav2").on('click', function() {
        if ($('#menu').css('display') != 'block') {
            hideAll('#menu');
            $('#menu').addClass('active');
            $('.nav hr').css('margin-left', '25%');
            ga('set', 'page', '/menukaart');
            ga('send', 'pageview');
        }
    });
    $("#nav3").on('click', function() {
        if ($('#reserveer').css('display') != 'block') {
            hideAll('#reserveer');
            $('#reserveer').addClass('active');
            $('.nav hr').css('margin-left', '50%');
            ga('set', 'page', '/reserveer');
            ga('send', 'pageview');
        }
    });
    $("#nav4").on('click', function() {
        if ($('#contact').css('display') != 'block') {
            hideAll('#contact');
            $('#contact').addClass('active');
            $('.nav hr').css('margin-left', '75%');
            ga('set', 'page', '/contact');
            ga('send', 'pageview');
            initMap();
        }
    });

    var mobile = document.createElement('div');
    mobile.className = 'nav-mobile';
    document.querySelector('nav').appendChild(mobile);

    // hasClass
    function hasClass(elem, className) {
        return new RegExp(' ' + className + ' ').test(' ' + elem.className + ' ');
    }

    // toggleClass
    function toggleClass(elem, className) {
        var newClass = ' ' + elem.className.replace(/[\t\r\n]/g, ' ') + ' ';
        if (hasClass(elem, className)) {
            while (newClass.indexOf(' ' + className + ' ') >= 0) {
                newClass = newClass.replace(' ' + className + ' ', ' ');
            }
            elem.className = newClass.replace(/^\s+|\s+$/g, '');
        } else {
            elem.className += ' ' + className;
        }
    }

    // Mobile nav function
    var mobileNav = document.querySelector('.nav-mobile');
    var toggle = document.querySelector('.nav');
    mobileNav.onclick = function() {
        toggleClass(this, 'nav-mobile-open');
        toggleClass(toggle, 'nav-active');
    };

    if (datefield.type != "date") { //if browser doesn't support input type="date", initialize date picker widget:
        jQuery(function($) { //on document.ready
            $('#r-date').datepicker();
            $("#r-date").on('change', function() {
                $('#r-date').datepicker("option", $.datepicker.regional['nl-BE']);
                $('#r-date').datepicker("option", "dateFormat", "DD, d MM, yy");
            });
        })
    }

    var valReserveer = new FormValidator('form-reserveer', [{
        name: 'r-name',
        display: 'Naam',
        rules: 'required'
    }, {
        name: 'r-email',
        display: "E-mail",
        rules: 'valid_email|required'
    }, {
        name: 'r-date',
        display: 'Datum',
        rules: 'required'
    }], function(errors, event) {
        if (errors.length > 0) {
            var errorString = '';

            for (var i = 0, errorLength = errors.length; i < errorLength; i++) {
                errorString += errors[i].message + '<br />';
            }
            el = document.getElementById("messages");
            el.innerHTML = '<div class="danger">' + errorString + '</div>';
            event.returnValue = false;
            return false;
        }
    });

    $('#form-reserveer').on('submit', function(e) {
        if (!e.isDefaultPrevented()) {
            grecaptcha.execute(clientId, {action: 'sendForm'}).then(function(token) {
                $("#submit").removeAttr('disabled');
                $("#captcha").val(token);
                var url = "reserveer.php";
                $.ajax({
                    type: "POST",
                    url: url,
                    dataType: "json",
                    data: $('#form-reserveer').serialize(),
                    success: function(data) {
                        const messageclass = data.type;
                        const messageText = data.message;
                        const alertBox = '<div class=' + messageclass + '>' + messageText + '</div>';
                        if (messageclass && messageText) {
                            $('#messages').html(alertBox);
                              if (messageclass === 'success') {
                                    $('#form-reserveer')[0].reset();
                                    $('#form-reserveer').fadeOut(400);
                                    setTimeout(function(){
                                        $('#form-reserveer').fadeIn(1000)
                                    },15000);
                                }
                        }
                    }
                });
            });

                return false;
        }
    });


});

function initMap() {
    var mouterij = { lat: 51.151798, lng: 3.609264 };
    var map = new google.maps.Map(document.getElementById('map'), { zoom: 15, center: mouterij });
    var marker = new google.maps.Marker({ position: mouterij, map: map, title: "De Mouterij", animation: google.maps.Animation.BOUNCE });
}

function hideAll(except) {
    if (except != '#home' && $('#home').css('display') != 'none') $('#home').removeClass('active');
    if (except != '#menu' && $('#menu').css('display') != 'none') $('#menu').removeClass('active');
    if (except != '#reserveer' && $('#reserveer').css('display') != 'none') $('#reserveer').removeClass('active');
    if (except != '#contact' && $('#contact').css('display') != 'none') $('#contact').removeClass('active');
    $('.nav-mobile').trigger('click');
}

function captcha_check() {
    $("#submit").removeAttr('disabled');
}